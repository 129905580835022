import * as React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';

import logo from './logo.svg';
import logoPng from './logo.png';
import style from './style.css';
import './App.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
    Avatar, Badge, Divider, Chip, Skeleton, Stack, IconButton, Link, Slider, FormControl, FormGroup, FormLabel,
    FormControlLabel, Checkbox, FormHelperText, CardHeader, CardMedia, CardActionArea, Fab, TextField, Autocomplete
} from "@mui/material";
import {Mail, Notifications, Bedtime, MoreVert, QuestionAnswer, ThumbUp, BookmarkAdd, Add} from "@mui/icons-material";
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import DraftsIcon from '@mui/icons-material/Drafts';
import SendIcon from '@mui/icons-material/Send';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import StarBorder from '@mui/icons-material/StarBorder';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


/*
START OF HEADER

COMPONENT REFERENCE 9046

*/
function Header(){
    React.useEffect(() => {

        function handleScroll() {
            const header = document.getElementById("pg-header");
            if (window.scrollY < header.offsetHeight / 10){
                if (header.classList.contains("sticky")) {
                    header.classList.remove("sticky");
                }
            }
            if (document.body.offsetHeight + header.offsetHeight > document.documentElement.clientHeight) {
                if (window.scrollY > header.offsetHeight) {
                    const sticky = header.offsetTop;
                    if (window.pageYOffset > sticky) {
                        header.classList.add("sticky");
                    } else {
                        if (header.classList.contains("sticky")) {
                            header.classList.remove("sticky");
                            // }// } else {}
                        }else{}
                    }
                }
            }
        }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
  return(
      <>
      <div id={'pg-header'} className={'header-wrapper'}>
          <div className={'header-logo-wrapper'}>
            <img src={logo} style={{maxHeight: '130px'}} />
          </div>
          <div>
              <div className={'navlink-cta-scaffold'}>
                <div className={'icon-button-wrapper'}>
                    <IconButton>
                        <Bedtime color={'disabled'} />
                    </IconButton>
                    <div style={{marginLeft: '20px'}}>
                        <IconButton href={'http://localhost:3000/messaging'}>
                            <Badge badgeContent={4} color="primary">
                                <Mail color="action" />
                            </Badge>
                        </IconButton>
                    </div>
                    <div style={{marginLeft: '20px'}}>
                        <IconButton>
                            <Badge badgeContent={2} color="primary">
                                <Notifications color="action" />
                            </Badge>
                        </IconButton>
                    </div>

                </div>
                  <div className={'navlink-wrapper'}>
                      <a href={'http://localhost:3000/'} style={{marginLeft: '25px'}}>Home</a>
                      <a href={'http://localhost:3000/jobs'} style={{marginLeft: '25px'}}>Jobs</a>
                      <a href={'http://localhost:3000/support'} style={{marginLeft: '25px'}}>Support</a>
                      <a href={'http://localhost:3000/forums'} style={{marginLeft: '25px'}}>Forums</a>
                      <a href={'http://localhost:3000/blog'} style={{marginLeft: '25px'}}>Blog</a>
                  </div>
              </div>
          </div>
      </div>
      </>
  )
}

/*
END OF HEADER
*/


/*
START OF FOOTER

COMPONENT REFERENCE 1927

*/

function Footer(){
    return(
        <>
        <div className={'footer-wrapper'}>
            <img src={logoPng} style={{maxHeight: '90px'}} />
            <p style={{fontSize: 'smaller', marginTop: '15px'}}>Allowing IT Professionals to connect & find their next big opportunity.</p>
        </div>
        </>
    )
}

/*
END OF FOOTER
*/

/*
START OF SIDEBAR

COMPONENT REFERENCE 8012

*/

function SideBar(){
    React.useEffect(() => {
        function handleScroll() {
           let header = document.getElementById('pg-header');
           let sidebar = document.getElementById('sdbar');
           if (header.classList.contains('sticky')){
               if (sidebar.style.marginTop == '15vh'){

               }else{
                   sidebar.style.marginTop = '15vh';
               }
           }else{
               if (sidebar.style.marginTop == '5vh'){

               }else{
                   sidebar.style.marginTop = '5vh';
               }
           }
            }
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };

    const handleDelete = () => {
        console.info('You clicked the delete icon.');
    };
    return(
        <>
            <div id={'sdbar'} className={'sidebar'} style={{gridArea: 'sidebar'}}>

                <Avatar style={{margin: 'auto', marginBottom: '10px'}}>JU</Avatar>
                <h2 style={{fontSize: 'small', textAlign: 'center'}}>Jerome Utah</h2>
                <h2 style={{fontSize: 'smaller', color: 'gray', textAlign: 'center'}}>Full Stack Developer</h2>
                <Chip style={{marginLeft: '3vw', marginTop: '1vh', marginBottom: '1vh'}} label="Looking for work" size={'small'} variant="outlined" onDelete={handleDelete} />
                <Divider medium />
                <div className={'sidebar-item'} style={{textAlign: 'start', marginTop: '3vh'}}>
                    <p className={'sub-emphasised-text'}>Connections</p>
                    <Link href={'#'} style={{textDecoration: 'none', color: 'black'}}>
                        <p className={'emphasised-text'}>Connect with Alumni</p>
                    </Link>
                </div>
                <div className={'sidebar-item'} style={{textAlign: 'start', marginTop: '3vh'}}>
                    <p className={'sub-emphasised-text'}>Who's noticing you</p>
                    <Link href={'#'} style={{textDecoration: 'none', color: 'black'}}>
                        <p className={'emphasised-text'}>Who's viewed your profile</p>
                    </Link>
                </div>
                <Divider style={{marginTop: '2vh'}} medium />
                <List
                    sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: '2vh' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Messaging:
                        </ListSubheader>
                    }
                >
                    <ListItemButton>
                        <ListItemIcon>
                            <SendIcon />
                        </ListItemIcon>
                        <ListItemText primary="Sent mail" />
                    </ListItemButton>
                    <ListItemButton>
                        <ListItemIcon>
                            <DraftsIcon />
                        </ListItemIcon>
                        <ListItemText primary="Drafts" />
                    </ListItemButton>
                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Inbox" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary="Starred" />
                            </ListItemButton>
                        </List>
                    </Collapse>
                </List>
            </div>
        </>
    )
}


/*
END OF SIDEBAR
*/


/*
START OF JOB SEARCH SIDEBAR

COMPONENT REFERENCE 1414

*/

function JobSearchSidebar({ filterData, onFilterChange}){
    const [openDistance, setOpenDistance] = React.useState(true);
    const [openType, setOpenType] = React.useState(false);
    const [openLocation, setOpenLocation] = React.useState(false);
    const [openExperience, setOpenExperience] = React.useState(false);


    const handleLocationClick = () => {
        setOpenLocation(!openLocation);
    };

    const handleExperienceClick = () => {
        setOpenExperience(!openExperience);
    };

    const handleTypeClick = () => {
        setOpenType(!openType);
    };

    const handleDistanceClick = () => {
        setOpenDistance(!openDistance);
    };

    function valueLabelFormat(value) {
        return `${value} Miles`;
    }

    function calculateValue(value) {
        return value;
    }

    const [value, setValue] = React.useState(10);

    const handleSliderChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setValue(newValue);
            filterData['distance'] = newValue;
            onFilterChange(filterData);
        }
    };



    const [state, setState] = React.useState({
        remote: filterData['remote'],
        onsite: filterData['onsite'],
        hybrid: filterData['hybrid'],
        fullTime: filterData['fullTime'],
        partTime: filterData['partTime'],
        temporary: filterData['temporary'],
        permanent: filterData['permanent'],
        internship: filterData['internship'],
        placement: filterData['placement'],
        contract: filterData['contract'],
        otherType: filterData['otherType'],
        entryLevel: filterData['entryLevel'],
        midLevel: filterData['midLevel'],
        seniorLevel: filterData['seniorLevel'],
        internLevel: filterData['internLevel'],
        otherLevel: filterData['otherLevel']
    });

    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked,
        });
        filterData[event.target.name] = event.target.checked;
        console.log(event.target.name);
        console.log(event.target.checked);
        console.log(filterData);
        onFilterChange(filterData);
    };

    const { remote, onsite, hybrid, fullTime, partTime, temporary, permanent, internship, placement, contract, otherType, entryLevel, midLevel, seniorLevel, internLevel, otherLevel} = state;
    const error = [remote, onsite, hybrid].filter((v) => v).length < 1 ;
    const jobTypeError = [fullTime, partTime, temporary, internship, placement, contract, otherType].filter((v) => v).length < 1 ;
    const experienceError = [entryLevel, midLevel, seniorLevel, internLevel, otherLevel].filter((v) => v).length < 1 ;

    return(
        <>
            <div className={'jobsearch-sidebar'}>
                <h2 style={{fontSize: 'medium'}}>Refine Search: </h2>
                <div className={'job-search-distance-slider-wrapper'}>
                    {/*<Slider style={{marginTop: '5vh'}} size={'medium'} color={'primary'} valueLabelDisplay={'on'} defaultValue={30} step={1} aria-label="Search Radius (Miles)" />*/}
                    <List
                        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', marginTop: '2vh' }}
                        component="nav"
                        aria-labelledby="nested-list-subheader"
                    >
                        <ListItemButton onClick={handleDistanceClick}>
                            <ListItemText primary="Distance:" />
                            {openDistance ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openDistance} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Box>
                                    <Typography style={{marginTop: '2vh'}} id="non-linear-slider" gutterBottom>
                                        <strong>Within:</strong> {value} Miles
                                    </Typography>
                                    <Slider
                                        value={value}
                                        min={1}
                                        step={1}
                                        defaultValue={30}
                                        max={100}
                                        scale={calculateValue}
                                        getAriaValueText={valueLabelFormat}
                                        onChange={handleSliderChange}
                                        aria-labelledby="non-linear-slider"
                                    />
                                </Box>
                            </List>
                        </Collapse>


                        <ListItemButton onClick={handleTypeClick}>
                            <ListItemText primary="Job Type" />
                            {openType ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>
                        <Collapse in={openType} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                <Box sx={{ display: 'flex' }}>

                                    <FormControl
                                        error={jobTypeError}
                                        component={"fieldset"}
                                        sx={{ m: 3 }}
                                        variant={"standard"}
                                    >
                                        <FormLabel component="legend">Job Type:</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={fullTime} onChange={handleCheckboxChange} name="fullTime" />
                                                }
                                                label="Full Time"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={partTime} onChange={handleCheckboxChange} name="partTime" />
                                                }
                                                label="Part Time"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={temporary} onChange={handleCheckboxChange} name="temporary" />
                                                }
                                                label="Temporary"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={permanent} onChange={handleCheckboxChange} name="permanent" />
                                                }
                                                label="Permanent"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={internship} onChange={handleCheckboxChange} name="internship" />
                                                }
                                                label="Internship"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={placement} onChange={handleCheckboxChange} name="placement" />
                                                }
                                                label="Work Placement"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={contract} onChange={handleCheckboxChange} name="contract" />
                                                }
                                                label="Contract"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={otherType} onChange={handleCheckboxChange} name="otherType" />
                                                }
                                                label="Other"
                                            />
                                        </FormGroup>
                                        <FormHelperText>Pick at least one</FormHelperText>
                                    </FormControl>
                                </Box>
                            </List>
                        </Collapse>


                        <ListItemButton onClick={handleLocationClick}>
                            <ListItemText primary="Job Location" />
                            {openLocation ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                            <Collapse in={openLocation} timeout="auto" unmountOnExit>
                                <Box sx={{ display: 'flex' }}>

                                    <FormControl
                                        error={error}
                                        component={"fieldset"}
                                        sx={{ m: 3 }}
                                        variant={"standard"}
                                    >
                                        <FormLabel component="legend">Job Location:</FormLabel>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={remote} onChange={handleCheckboxChange} name="remote" />
                                                }
                                                label="Remote"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={onsite} onChange={handleCheckboxChange} name="onsite" />
                                                }
                                                label="Onsite"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox checked={hybrid} onChange={handleCheckboxChange} name="hybrid" />
                                                }
                                                label="Hybrid"
                                            />
                                        </FormGroup>
                                        <FormHelperText>Pick at least one</FormHelperText>
                                    </FormControl>
                                </Box>
                            </Collapse>

                        <ListItemButton onClick={handleExperienceClick}>
                            <ListItemText primary="Experience Level" />
                            {openExperience ? <ExpandLess /> : <ExpandMore />}
                        </ListItemButton>

                        <Collapse in={openExperience} timeout="auto" unmountOnExit>
                            <Box sx={{ display: 'flex' }}>

                                <FormControl
                                    error={experienceError}
                                    component={"fieldset"}
                                    sx={{ m: 3 }}
                                    variant={"standard"}
                                >
                                    <FormLabel component="legend">Experience Level:</FormLabel>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={entryLevel} onChange={handleCheckboxChange} name="entryLevel" />
                                            }
                                            label="Entry Level"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={midLevel} onChange={handleCheckboxChange} name="midLevel" />
                                            }
                                            label="Mid Level"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={seniorLevel} onChange={handleCheckboxChange} name="seniorLevel" />
                                            }
                                            label="Senior"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={internLevel} onChange={handleCheckboxChange} name="internLevel" />
                                            }
                                            label="Intern"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox checked={otherLevel} onChange={handleCheckboxChange} name="otherLevel" />
                                            }
                                            label="Other"
                                        />
                                    </FormGroup>
                                    <FormHelperText>Pick at least one</FormHelperText>
                                </FormControl>
                            </Box>
                        </Collapse>
                    </List>
                </div>
            </div>
        </>
    );
}


/*
END OF SIDEBAR
*/
function JobOffer(){
    return(
        <>
            <div className={'job-offer bs-subtle'}>
                <div className={'text-wrapper'} style={{width: '60%', margin: 'auto'}}>
                    <h2>Your Job Offer:</h2>
                    <br />
                    <p>Congratulations, Jerome! You have been offered a role through RecruitThe.Dev.</p>
                    <p style={{marginTop: '15px'}}><strong>McDonald's UK & Ireland</strong> Have offered you the role: <strong>Full Stack Web Developer!</strong>
                    <br /><br />
                    Based in: <strong>Bristol, Avon, United Kingdom</strong>.
                    <br /><br />
                    The Team @ Recruit the Dev would like to congratulate you on making it this far and to thank you for using our platform
                    in your job hunting journey.
                    <br /><br />
                    <strong>McDonald's UK & Ireland</strong> have requested an answer to this offer within <strong>72 Hours</strong>, meaning
                    you need to choose to either<br/>
                        <br/><strong><span style={{color: 'green'}}>Accept</span> or <span style={{color: 'red'}}>Decline</span></strong>
                        <br/><br/> this offer
                    before <strong>17:00 on April 25th 2023</strong> Remember to decline the offer if you do not wish to accept the role, ignoring offers
                    is frowned upon in the industry and is seen as rude and dismissive.
                    <br /><br />
                    Good Luck.<br />
                    Sincerely,<br />
                    <br />
                    The Recruit The Dev Team!
                    </p>
                </div>
            </div>

            <Stack style={{width: '50%' ,margin: 'auto', marginTop: '2vh'}} spacing={1}>
                {/* For variant="text", adjust the height via font-size */}
                <Skeleton variant="text" sx={{ fontSize: '4rem' }} />
                {/* For other variants, adjust the size with `width` and `height` */}
                <Skeleton variant="circular" width={40} height={40} />
                <Skeleton variant="rectangular" width={210} height={60} />
                <Skeleton variant="rounded" width={210} height={60} />
            </Stack>
        </>
    )
}

function JobOfferPage(){
    return (
        <div className="App">
            <Header />
            <div className={'body-scaffold'}>
                <SideBar />
                <div style={{gridArea: 'body'}}>
                    <JobOffer />
                </div>
            </div>
            <Footer />
        </div>
    );
}



function HomePage(){
    const bull = (
        <Box
            component="span"
            sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)' }}
        >
            •
        </Box>
    );
    return(
        <>
            <Header />
            <div className={'body-scaffold'}>
                <SideBar />
                <div style={{gridArea: 'body'}}>
                    <div className={'home-content-body'}>
                       <div style={{width: '70%', margin: 'auto', marginTop: '3vh'}}>
                           <Card sx={{ minWidth: 275, marginTop: '2vh' }}>
                               <CardContent>
                                   <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                       Word of the Day
                                   </Typography>
                                   <Typography variant="h5" component="div">
                                       be{bull}nev{bull}o{bull}lent
                                   </Typography>
                                   <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                       adjective
                                   </Typography>
                                   <Typography variant="body2">
                                       well meaning and kindly.
                                       <br />
                                       {'"a benevolent smile"'}
                                   </Typography>
                               </CardContent>
                               <CardActions>
                                   <Button size="small" target={'_blank'} href={'https://en.wiktionary.org/wiki/benevolent'}>Learn More</Button>
                               </CardActions>
                           </Card>
                           <Card sx={{ minWidth: 275, marginTop: '2vh' }}>
                               <CardContent>
                                   <div style={{display: 'inline-flex'}}>
                                       <Avatar>JM</Avatar>
                                       <div style={{marginTop: '0.5vh'}}>
                                           <span style={{marginLeft: '0.5vw', fontWeight: 'bold', fontSize: 'medium'}}>Jamal Matthews</span>
                                           <Chip style={{marginLeft: '20px'}} label="Head of UX / Product Design @ Chick-Fil-a" variant="outlined" />
                                           <h2 style={{fontSize: 'smaller', marginBottom: '2vh', color: 'grey', fontWeight: 'normal', marginLeft: '0.5vw'}}>You follow this user</h2>
                                       </div>
                                   </div>

                                    <div style={{maxWidth: '95%', margin: 'auto'}}>
                                        <p>
                                            Good Morning, to all of my connections, alumni and followers. I am writing this quick
                                            status update to let everyone know that I am delighted to be starting a new position
                                            as <strong>Product Designer</strong> and <strong>Head of UX</strong> for <strong>Chick-fil-a US</strong>.
                                            <br /><br />
                                            As many of you may know, I previously worked as head of internal communications for
                                            <strong> IKEA UK/IE</strong>. However as some of you will know, I've been looking for
                                            a way out for a while. I am excited to say that I will be relocating to California for my new role!
                                            <br /><br />
                                            I am leaving at the beginning of June and will be planning a leaving mixer soon. I will send out as
                                            many invites as I can and wish for as many of you as possible to attend, please message me if you
                                            would like to come.
                                        </p>
                                    </div>
                               </CardContent>
                               <CardActions style={{marginLeft: '1vw'}}>
                                    <IconButton>
                                        <Badge badgeContent={2} color="primary">
                                            <QuestionAnswer />
                                        </Badge>
                                    </IconButton>
                                   <IconButton>
                                            <ThumbUp color={'primary'} />
                                    </IconButton>
                                   <span  style={{color: 'grey', fontSize: 'small'}}>219 Likes</span>
                               </CardActions>
                           </Card>

                           <Card sx={{ minWidth: 275, marginTop: '2vh' }}>
                               <CardContent>
                                   <div style={{display: 'inline-flex'}}>
                                       <Skeleton variant="circular" width={40} height={40} />
                                   </div>

                                    <div style={{maxWidth: '95%', margin: 'auto', marginTop: '3vh'}}>
                                        <Box sx={{ width: 600 }}>
                                            <Skeleton />
                                            <Skeleton animation="wave" />
                                            <Skeleton animation={false} />
                                        </Box>
                                    </div>
                               </CardContent>
                               <CardActions style={{marginLeft: '1vw'}}>
                                    <IconButton>
                                        <Skeleton variant="text" sx={{ fontSize: '1rem' }}>
                                            <QuestionAnswer />
                                        </Skeleton>
                                    </IconButton>
                                   <IconButton>
                                       <Skeleton variant="text" sx={{ fontSize: '1rem' }}>
                                            <ThumbUp color={'primary'} />
                                       </Skeleton>
                                    </IconButton>
                                   <Skeleton variant="text" sx={{ fontSize: '1rem' }}>
                                       <span  style={{color: 'grey', fontSize: 'small'}}>219 Likes</span>
                                   </Skeleton>
                               </CardActions>
                           </Card>
                       </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}


let dbCurrentPage = 1;
let storeJobPosts;

const JobPostList = (props) => {
    const [jobPosts, setJobPosts] = React.useState([]);
    const [filters, setFilters] = React.useState(props.filterData);
    const [showPerPage, setShowPerPage] = React.useState(10);
    let [currentPage, setCurrentPage] = React.useState(1);
    const [visible, setVisible] = React.useState(10);
    const [total, setTotal] = React.useState(0);
    const [showingFrom, setShowingFrom] = React.useState(0);
    const [showingTo, setShowingTo] = React.useState(0);


    React.useEffect(() => {
        const fetchData = async () => {
            try {
                console.log('SELECTING JOB POSTS');
                // const response = await axios.post("http://localhost:3001/get/filtered-job-posts", {
                //     filterData: filters
                // });
                const response = await axios.get(`http://localhost:3001/get/paged-job-posts?page=${currentPage}`);
                const total = response.data.length;
                setJobPosts(response.data);
                storeJobPosts = response.data;
                setTotal(total);
                updateShowingCount();
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();

        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [filters]);

    React.useEffect(() => {
        window.addEventListener("scroll", handleScroll);

        // Clean up the event listener
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handleLoadMore = () => {
        console.log('handling load more!');
        const fetchMoreData = async () => {
            try {
                console.log(dbCurrentPage + 1);
                setCurrentPage(dbCurrentPage);
                let url = `http://localhost:3001/get/paged-job-posts?page=${dbCurrentPage + 1}`;
                console.log(`URL: ${url}`);
                const response = await axios.get(url)
                    .then((response) => {
                        if (response.data.length > 0) {
                            // console.log(response.data);
                            const addToTotal = response.data.length;
                            let newTotal = total + addToTotal;
                            setTotal(newTotal);
                            // console.log(`JOB POSTS 0 ${jobPosts[0]}`);
                            // console.log(jobPosts[0]);
                            let updatedPosts = storeJobPosts;
                            for (let i = 0; i < response.data.length; i++) {
                                updatedPosts.push(response.data[i]);
                            }
                            console.log('UPDATED POSTS');
                            console.log(updatedPosts);
                            setJobPosts(updatedPosts);
                            setVisible(prevVisible => prevVisible + showPerPage);
                            updateShowingCount();
                            dbCurrentPage += 1;
                        }else{
                            console.log('no data has been returned when handling load more call');
                        }

                        let uniqueListings = {};

                        for (let i = jobPosts.length - 1; i >= 0; i--) {
                            const item = jobPosts[i];
                            if (!uniqueListings[item.ListingID]) {
                                uniqueListings[item.ListingID] = true;
                            } else {
                                jobPosts.splice(i, 1);
                            }
                        }

                    });


            }catch (error){
                console.log(error);
            }
        }
        fetchMoreData();

    };

    const handleScroll = () => {
        const scrollTop =
            (document.documentElement && document.documentElement.scrollTop) ||
            document.body.scrollTop;
        const scrollHeight =
            (document.documentElement && document.documentElement.scrollHeight) ||
            document.body.scrollHeight;
        const clientHeight =
            document.documentElement.clientHeight || window.innerHeight;
        const scrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

        if (scrolledToBottom) {
            handleLoadMore();
        }
    };

    const updateShowingCount = () => {
        const showingFrom = Math.min(1, total);
        const showingTo = Math.min(visible, total);
        setShowingFrom(showingFrom);
        setShowingTo(showingTo);
    };

    return (
        <>
            {/*<p>REN COUNT {props.renCount}</p>*/}
            <div style={{ marginTop: "2vh", marginLeft: "3vw", color: "grey" }}>
                <p style={{ fontSize: "larger" }}>
                    Showing {showingFrom} - {showingTo} of {total} Listings
                </p>
            </div>
            <div style={{ marginTop: "3vh", marginLeft: "3vw", display: "grid", gridTemplateColumns: "50% 50%" }}>
                {jobPosts.slice(0, visible).map((jobPost) => (
                    <JobPostCard key={jobPost.id} jobPost={jobPost} />
                ))}
            </div>
            {visible < total && (
                <div style={{ marginTop: "2vh", marginLeft: "3vw" }}>
                    <Button variant="contained" color="primary" onClick={handleLoadMore}>
                        Load More
                    </Button>
                </div>
            )}
            <div style={{ marginTop: "2vh", marginLeft: "3vw", color: "grey", marginBottom: '2vh'}}>
                <p style={{ fontSize: "larger" }}>
                    Showing {showingFrom} - {showingTo} of {total} Listings
                </p>
            </div>
        </>
    );
};


function JobPostCard({ jobPost }) {
    const [distance, setDistance] = React.useState(<Skeleton />);
    const [jobInfo, setJobInfo] = React.useState({job_type: '~', jobLocation: '~'})

    function getTimeSince(timestamp) {
        const now = new Date();
        // console.log(`Timestamp as given to func:`);
        if (!(timestamp instanceof Date)) {
            // return a default string or throw an error here
            timestamp = new Date(timestamp);
            // console.log(`New Timestamp: ${timestamp}`);
        }
        // console.log(new Date());
        const diffMs = now.getTime() - timestamp.getTime();
        if (diffMs == NaN){
            // console.log('None lol');
        }
        // console.log(`DIFF MS ${diffMs}`);
        const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
        const diffWeeks = Math.floor(diffDays / 7);
        // console.log(`Diff Weeks ${diffWeeks}`);

        if (diffDays === 0) {
            return 'Listed Today';
        } else if (diffDays === 1) {
            return 'Listed Yesterday';
        } else if (diffWeeks === 0) {
            return `Listed ${diffDays} Days ago`;
        } else if (diffWeeks === 1) {
            return 'Listed 1 Week ago';
        } else {
            return `Listed ${diffWeeks} Weeks ago`;
        }
    }

    function calculateDistance(lat1, lon1, lat2, lon2) {
        const R = 6371; // Earth's radius in kilometers
        const dLat = toRadians(lat2 - lat1);
        const dLon = toRadians(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(toRadians(lat1)) *
            Math.cos(toRadians(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
    }

    function toRadians(degrees) {
        return degrees * (Math.PI / 180);
    }


    jobPost.timeSince = getTimeSince(jobPost['listing_timestamp']);

    React.useEffect(() => {
        navigator.geolocation.getCurrentPosition(function(position) {
            const userLat = position.coords.latitude;
            const userLon = position.coords.longitude;
            const calculatedDistance = calculateDistance(userLat, userLon, jobPost['listing_latitude'], jobPost['listing_longitude']);
            // console.log(`Job Post Distance ${calculatedDistance}`); // Output: distance in kilometers
            setDistance(`${calculatedDistance.toFixed(1)} Kilometres away`);
        });
    }, [jobPost]);


    return (
        <>
            <div style={{margin: '20px'}}>
                <Card style={{width: '30vw'}}>
                    <CardActionArea>
                        <CardContent>
                            <p>LISTING ID: {jobPost['ListingID']}</p>
                            <div style={{display: 'inline-flex'}}>
                                <CardMedia style={{maxWidth: '5vw'}} src={jobPost['profile_picture_image_source']} component={'img'} />
                                <div style={{display: 'block'}}>
                                    <h2 style={{marginLeft: '15px', fontSize: 'medium'}}>{jobPost['listing_title']}</h2>
                                    <p style={{marginLeft: '15px', fontSize: 'small'}}>{jobPost['company_name']}</p>
                                    <p style={{marginLeft: '15px', color: 'grey', fontSize: 'small'}}>{jobPost['geo_location']}</p>
                                    <p style={{marginLeft: '15px' ,marginTop: '15px'}}><strong>Based on your skills, you could be a good fit for this role.</strong></p>
                                </div>
                            </div>
                            <div style={{marginTop: '5px', padding: '5px'}}>
                                <Chip label={jobPost['job_type']} />
                                <Chip style={{marginLeft: '5px'}} label={jobPost['job_location']} />
                                <Chip style={{marginLeft: '5px'}} label="Permanent" />
                            </div>
                            <p style={{overflow: 'hidden', textOverflow: 'ellipsis', maxHeight: '30px', lineHeight: '14px',
                                display: '-webkit-box', WebkitLineClamp: '2', WebkitBoxOrient: 'vertical'}}>
                                {jobPost['listing_description']}
                            </p>
                            <div style={{marginTop: '12px', display: 'grid', gridTemplateColumns: '50% 50%'}}>
                                <div>
                                    <p style={{fontSize: 'small', fontWeight: 'bolder'}}>{jobPost.timeSince}</p>
                                    <p style={{marginTop: '10px'}}>{distance}</p>
                                </div>
                                <div style={{marginRight: '0', marginLeft: 'auto'}}>
                                    <IconButton>
                                        <BookmarkAdd />
                                    </IconButton>
                                </div>

                            </div>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </div>
        </>
    );
}

const initialFilterData = {
    distance: 10,
    fullTime: true,
    partTime: true,
    temporary: false,
    permanent: true,
    internship: false,
    placement: false,
    contract: false,
    otherType: false,
    entryLevel: true,
    midLevel: true,
    seniorLevel: true,
    internLevel: true,
    otherLevel: true,
    onsite: true,
    remote: true,
    hybrid: true
};


function JobsPage() {
    const [numDisplayed, setNumDisplayed] = React.useState(0);
    const [renCount, setRenCount] = React.useState(0);
    const [filterData, setFilterData] = React.useState(initialFilterData);

    const handleJobPostListRender = (numJobPosts) => {
        setNumDisplayed(numJobPosts);
    };


    const handleFilterChange = (newFilterData) => {
        setFilterData(newFilterData);
        console.log('called handle filter change');
        console.log(filterData);
        setRenCount(renCount + 1);
    };

    return (
        <>
            <Header />
            {/*<p>REN COUNT {renCount}</p>*/}
            <div className={'jobs-body-scaffold'}>
                <div style={{ display: 'inline-flex', marginLeft: '2vw', position: 'fixed' }}>
                    <SideBar />
                    <div style={{ marginLeft: '2vw' }}> </div>
                    <JobSearchSidebar filterData={filterData} onFilterChange={handleFilterChange} renCount={renCount} />
                </div>

                <div style={{ gridArea: 'body' }}>
                    <div className={'home-content-body'}>
                {/*        <div*/}
                {/*            style={{*/}
                {/*                marginTop: '2vh',*/}
                {/*                marginLeft: '3vw',*/}
                {/*                color: 'grey',*/}
                {/*            }}*/}
                {/*        >*/}
                {/*            <p style={{ fontSize: 'larger' }}>*/}
                {/*                Showing -{' '}*/}
                {/*                <span style={{ color: 'blue' }}>*/}
                {/*  {numDisplayed > 0 ? 1 : 0}*/}
                {/*</span>{' '}*/}
                {/*                to{' '}*/}
                {/*                <span style={{ color: 'blue' }}>*/}
                {/*  {numDisplayed > 0 ? numDisplayed : 0}*/}
                {/*</span>{' '}*/}
                {/*                of <span style={{ color: 'blue' }}>200</span> Listings*/}
                {/*            </p>*/}
                {/*        </div>*/}
                        <JobPostList onRender={handleJobPostListRender} filterData={filterData} renCount={renCount} />
                    </div>
                </div>
            </div>
            <Link href={'http://localhost:3000/jobs/create'}>
                <Fab color="primary" aria-label="add" style={{top: '90vh', left: '95vw', position: 'fixed'}}>
                    <Add />
                </Fab>
            </Link>
        </>
    );
}


// const TagInput = () => {
//     const [tags, setTags] = React.useState([]);
//
//     const handleTagChange = (_, newTags) => {
//         setTags(newTags);
//     };
//
//     const handleAddTag = () => {
//         // Handle adding the selected tag to your data or state
//         console.log(tags);
//     };
//
//     return (
//         <div>
//             <Autocomplete
//                 multiple
//                 options={['Tag 1', 'Tag 2', 'Tag 3', 'Tag 4']} // Replace with your tag options
//                 freeSolo
//                 value={tags}
//                 onChange={handleTagChange}
//                 renderInput={(params) => (
//                     <TextField
//                         {...params}
//                         label="Tags"
//                         placeholder="Add tags"
//                     />
//                 )}
//             />
//             <Button variant="contained" color="primary" onClick={handleAddTag}>
//                 Add Tag
//             </Button>
//         </div>
//     );
// };

const TagInput = () => {
    const [tags, setTags] = React.useState([]);

    const handleTagChange = (_, newTags) => {
        setTags(newTags);
    };

    const handleAddTag = () => {
        // Handle adding the selected tag to your data or state
        console.log(tags);
    };

    const getOptions = async (inputValue) => {
        try {
            const response = await fetch(`/api/tags?search=${inputValue}`);
            const data = await response.json();
            return data.tags; // Adjust this according to your API response structure
        } catch (error) {
            console.error('Error fetching tag suggestions:', error);
            return [];
        }
    };

    return (
        <div>
            <Autocomplete style={{width: '35vw'}}
                multiple
                options={[]}
                getOptionSelected={(option, value) => option === value}
                getOptionLabel={(option) => option}
                filterOptions={(options) => options} // Disable default filtering to allow server-side filtering
                freeSolo
                value={tags}
                onChange={handleTagChange}
                onInputChange={(event, newInputValue) => {
                    if (newInputValue !== '') {
                        getOptions(newInputValue)
                            .then((suggestions) => {
                                if (suggestions) {
                                    // Update the options with the fetched suggestions
                                    setTags((prevTags) => [...prevTags, ...suggestions]);
                                }
                            })
                            .catch((error) => {
                                console.error('Error getting tag suggestions:', error);
                            });
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Tags"
                        placeholder="Add tags"
                    />
                )}
            />
        </div>
    );
};

function CreateJobListingPage () {

    return(
        <>
            <Header />
            <div>
                <div style={{width: '65vw', margin: 'auto', marginTop: '5vh'}}>
                    <Card style={{padding: '20px'}}>
                        <h2>Create a new Job Listing...</h2>
                        <Box
                            component="form"
                            sx={{
                                '& > :not(style)': { m: 1, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField required={'true'} helperText={'Using a commonly accepted Job Title will usually lead to more applications. You can provide more detailed info\n' +
                                '                            in the job description.'} style={{marginTop: '25px', width: '35vw'}} id="outlined-basic" label="Listing Title" variant="outlined" />
                            {/*<p style={{color: 'grey', width: '55vw', fontSize: 'smaller'}}>Using a commonly accepted Job Title will usually lead to more applications. You can provide more detailed info*/}
                            {/*in the job description.</p>*/}
                            <div></div>
                            <TextField multiline required={'true'} label={'Listing Description'} variant={"outlined"} style={{width: '35vw'}} />
                            <TagInput />
                            <p style={{color: 'grey', width: '55vw', fontSize: 'smaller'}}>Tags are used to help show your listing to relevant users. Include tags as accurate as possible to ensure the best possible organic reach.</p>
                        </Box>
                    </Card>
                </div>
            </div>
        </>
    )
}

function LoginPage() {
    const [credentials, setCredentials] = React.useState({ username: '', password: '' });

    function login(credentials) {
        fetch('https://localhost:3001/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
                if (response.ok) {
                    // Redirect the user to the dashboard page
                    window.location.href = '/dashboard';
                } else {
                    throw new Error('Login failed');
                }
            })
            .catch(error => {
                console.error(error);
            });
    }

    return(
        <>
            <label htmlFor={"username"}>Username:</label>
            <input type={"text"} id={"username"} value={credentials.username} onChange={e => setCredentials({ ...credentials, username: e.target.value })} />

            <label htmlFor={"password"}>Password:</label>
            <input type={"password"} id={"password"} value={credentials.password} onChange={e => setCredentials({ ...credentials, password: e.target.value })} />
            <button onClick={() => login(credentials)}>Login</button>
        </>
    );
}


function App() {
  return(
      <Router>
          <Switch>
              <Route exact path="/">
                  <HomePage />
              </Route>
          </Switch>
          <Switch>
              <Route exact path="/jobs">
                  <JobsPage />
              </Route>
          </Switch>
          <Switch>
              <Route exact path="/jobs/create">
                  <CreateJobListingPage />
              </Route>
          </Switch>
          <Switch>
              <Route exact path="/job-listing">
                  <Header />
              </Route>
          </Switch>
          <Switch>
              <Route exact path="/job-offer">
                  <JobOfferPage />
              </Route>
          </Switch>
          <Switch>
              <Route exact path="/messaging">
                  <Header />
              </Route>
          </Switch>
      </Router>
  );
}

export default App;
